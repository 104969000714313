import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as Herz from '@rushplay/herz'

import * as gameCatalog from '../game-catalog'
import * as configuration from '../configuration'

import { LandingPageCategory as Component } from './landing-page-category'

const LandingPageCategory = R.compose(
  configuration.withClientType,
  connect(
    () => {
      const getGameSections = gameCatalog.createGetGameSections()
      return state => {
        const getGameCategoryId = gameCatalog.createGetGameCategoryId()
        return {
          sections: getGameSections(state.gameCatalog, {
            category: getGameCategoryId(state.gameCatalog, {
              catalog: 'landing-page',
              categoryKey: 'main',
            }),
          }),
        }
      }
    },
    (dispatch, props) => {
      return {
        onFetch: () =>
          dispatch(
            api.fetchGameCatalog('landing-page', {
              cacheFor: process.browser ? 120000 : null,
              success: res => [
                api.fetchGameCatalogCategories(
                  {
                    categoryId: R.path(['categories', 0, 'id'], res.value),
                    clientType: props.clientType,
                  },
                  {
                    cacheFor: process.browser ? 60000 : null,
                    success: catRes => [
                      gameCatalog.updateGameCatalogCategories({
                        [R.path(['categories', 0, 'id'], res.value)]: {
                          sections: Herz.Utils.Arrays.pluck(
                            'id',
                            R.path(['sections'], catRes.value)
                          ),
                        },
                      }),
                      gameCatalog.updateGameCatalogSections(
                        R.map(
                          section =>
                            R.merge(section, {
                              games: Herz.Utils.Arrays.pluck(
                                'id',
                                R.path(['games'], section)
                              ),
                            }),
                          R.indexBy(
                            R.path(['id']),
                            R.path(['sections'], catRes.value)
                          )
                        )
                      ),
                      gameCatalog.updateGameCatalogGames(
                        R.reduce(
                          (games, section) =>
                            R.merge(
                              games,
                              R.indexBy(
                                R.path(['id']),
                                R.path(['games'], section)
                              )
                            ),
                          {},
                          R.path(['sections'], catRes.value)
                        )
                      ),
                    ],
                    version: 1,
                  }
                ),
                gameCatalog.updateGameCatalog(
                  'landing-page',
                  Herz.Utils.Arrays.pluck(
                    'id',
                    R.path(['categories'], res.value)
                  )
                ),
                gameCatalog.updateGameCatalogCategories(
                  R.indexBy(R.path(['id']), R.path(['categories'], res.value))
                ),
              ],
              version: 1,
            })
          ),
        onLoadMore: (sectionId, offset) =>
          dispatch(
            api.fetchGameCatalogSections(
              { clientType: props.clientType, sectionId, offset, limit: 24 },
              {
                success: res => [
                  gameCatalog.updateGameCatalogSections({
                    [sectionId]: R.merge(res.value, {
                      games: Herz.Utils.Arrays.pluck(
                        'id',
                        R.path(['games'], res.value)
                      ),
                    }),
                  }),
                  gameCatalog.updateGameCatalogGames(
                    R.indexBy(R.path(['id']), R.path(['games'], res.value))
                  ),
                ],
                version: 1,
              }
            )
          ),
      }
    }
  ),
  recompose.mapProps(R.omit(['clientType']))
)(Component)

// For @loadable/components
export default LandingPageCategory
