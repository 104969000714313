import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { CasinoGameTile } from '../casino-game-tile'
import { Parallax } from '../parallax'

const LIVE_CASINO_KEY_WORLDS = [
  'live',
  'roulette',
  'blackjack',
  'baccarat',
  'poker',
  'shows',
]

function isLiveCasino(section) {
  return LIVE_CASINO_KEY_WORLDS.some(word => section.includes(word))
}

const GameRowWrapper = styled(Common.Box)`
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const GameTileWrapper = styled.div`
  scroll-snap-align: start;
`

export function LandingPageCategory(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      'landing-page.game-section.show-more',
      ...props.sections.map(section => `landing-page.${section.key}`),
    ],
    [props.sections]
  )

  React.useEffect(() => {
    props.onFetch()
  }, [])

  if (Object.keys(props?.sections)?.length === 0) {
    return null
  }

  return (
    <Common.Box mx="1">
      {props.sections.map(
        section =>
          Object.keys(section?.games)?.length > 0 && (
            <Common.Box key={`landing-page.-${section.key}`} pb="3">
              <Common.Box
                display="flex"
                justifyContent="space-between"
                fontSize="16px"
                color="#4cbbeb"
                py="2"
              >
                <Common.Box display="flex" alignItems="center">
                  <Common.Box fontSize="20px" fontWeight="600" color="#4cbbeb">
                    {translate(`landing-page.${section.key}`)}
                  </Common.Box>
                </Common.Box>
                <Common.Box
                  as="a"
                  href={isLiveCasino(section.key) ? '/live-casino' : '/casino'}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Common.Text
                    textDecoration="underline"
                    color="#4cbbeb"
                    fontSize="14px"
                  >
                    {translate('landing-page.game-section.show-more')}
                  </Common.Text>
                </Common.Box>
              </Common.Box>
              <GameRowWrapper
                gridGap={['10px', null, null, '20px']}
                gridAutoColumns={['50%', '33%', null, '24%']}
              >
                {section?.games.map(gameId => (
                  <GameTileWrapper key={gameId}>
                    <ReactRouter.Link to={`/casino/games/${gameId}`}>
                      <Parallax>
                        <CasinoGameTile id={gameId} size={section.tileSize} />
                      </Parallax>
                    </ReactRouter.Link>
                  </GameTileWrapper>
                ))}
              </GameRowWrapper>
            </Common.Box>
          )
      )}
    </Common.Box>
  )
}

LandingPageCategory.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      layout: PropTypes.oneOf(['row', 'grid']),
      tileSize: PropTypes.oneOf(['small', 'medium', 'large']),
      totalGamesCount: PropTypes.number.isRequired,
      games: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  onFetch: PropTypes.func.isRequired,
}
